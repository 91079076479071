@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Graphik", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
input[type="file"] {
  color: transparent;
}
::-webkit-file-upload-button {
  display: none;
}

input[type="checkbox"] {
  outline: none;
  box-shadow: none;
}

@font-face {
  font-family: "Graphik Regular";
  src: url("./assets/fonts/Graphik-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Graphik Medium";
  src: url("./assets/fonts/Graphik-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Graphik Semibold";
  src: url("./assets/fonts/Graphik-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Graphik Bold";
  src: url("./assets/fonts/Graphik-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
}

@layer base {
  h1 {
    @apply font-sans font-semibold text-lg;
  }
  h2 {
    @apply font-sans font-semibold text-base;
  }
}

@layer components {
  .nav-tabs .nav-link.active {
    @apply bg-primary-50 thryve:bg-secondary-50;
    @apply text-white;
  }

  .nav-tabs .nav-link:hover {
    @apply bg-grey-20;
  }

  .nav-tabs .nav-link.active:hover {
    @apply bg-primary-70 thryve:bg-secondary-50 thryve:opacity-90;
  }

  .input-group .input-group-text:last-child {
    @apply pl-1 pr-3 border-gray-300 border;
    border-left: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .input-group .input-group-text:first-child {
    @apply pl-3 pr-1 border-gray-300 border;
    border-right: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
}

/*Footer Styles*/
.footer-items {
  @apply w-[6rem] h-[2.56] flex flex-col justify-between items-center cursor-pointer;
}

.footer-items-text {
  @apply font-normal text-grey-40 text-xs font-sans;
}

.active-footer {
  @apply text-primary-90 font-normal text-xs font-sans;
}

.otp-input div:first-child input {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.otp-input div:last-child input {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.otp-input input:focus-visible,
input.PhoneInputInput:focus-visible {
  outline: none;
}

.form-control.PhoneInput.PhoneInput--disabled:hover,
.form-control.PhoneInput.PhoneInput--disabled > * {
  cursor: not-allowed;
}

.form-control.PhoneInput.sm input {
  height: 2rem;
}

.form-control.PhoneInput.md input {
  height: 2.5rem;
}

input.form-control:disabled {
  color: #566370b2;
  background-color: #f2f2f2;
  cursor: not-allowed;
}

.input-group {
  @apply flex flex-row items-center;
}

.input-group input {
  @apply flex-grow;
}

/* DropZone styling */
.dropzone {
  border: 1px solid black;
  @apply flex flex-col items-center justify-center py-6 text-center hover:cursor-pointer border-gray-300 rounded;
}

.form-floating > .form-control:focus ~ label {
  @apply font-medium text-sm;
  opacity: 1;
}

.form-floating > label {
  width: 100%;
}

.form-floating > .form-control ~ label {
  opacity: 1;
}

.form-floating > .form-control:not(:placeholder-shown):valid ~ label {
  @apply text-primary-70 thryve:text-secondary-50 font-medium text-sm;
}

.form-floating textarea.form-control {
  height: auto;
}

.form-check-input:checked {
  @apply bg-primary-50 border border-primary-50;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

input {
  @apply h-14;
}

.carousel-indicators [data-bs-target] {
  @apply h-4 w-4;
  border-radius: 100%;
}

.nav-tabs.secondary .nav-link.active {
  @apply bg-white border-b-2 border-b-base text-black;
}

.nav-tabs.secondary .nav-link.active:hover {
  @apply bg-[#00000010] text-black;
}

.brand {
  background: linear-gradient(90deg, #ffad03 0%, #fe8005 65.1%, #fd5308 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.subscription-plans {
  background: #ffffff;
  border: 2px solid #ffffff;
  box-shadow: inset 0px 3px 20px rgba(0, 0, 0, 0.15);
}

.active-subscription-plan {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.35);
  border-radius: 100px;
}

input[type="number"].appearance-none {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: textfield;
}

.dropdown {
  position: relative;
}

.menu {
  position: absolute;

  list-style-type: none;
  margin: 5px 0;
  padding: 0;

  border: 1px solid grey;
  width: max-content;
}

.menu > li {
  margin: 0;
  background-color: white;
}

.menu > li:hover {
  background-color: lightgray;
}

.menu > li > button {
  width: 100%;
  height: 100%;
  text-align: left;

  background: none;
  color: inherit;
  border: none;
  padding: 5px;
  margin: 0;
  font: inherit;
  cursor: pointer;
}

input[type="datetime-local"]::-webkit-inner-spin-button,
input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  display: block;
  background: url("/public/images/datePickerIcon.svg") no-repeat;
}

/*input[type="date"]::-webkit-calendar-picker-indicator {*/
/*  color: rgba(0, 0, 0, 0);*/
/*  opacity: 1;*/
/*  display: block;*/
/*  background: url("/public/images/datePickerIcon.svg") no-repeat;*/
/*  width: 20px;*/
/*  height: 20px;*/
/*  border-width: thin;*/
/*  margin-bottom: 16px;*/
/*}*/

/*input[type="date"]:required:invalid::-webkit-datetime-edit {*/
/*  color: transparent;*/
/*}*/
/*input[type="date"]:focus::-webkit-datetime-edit {*/
/*  color: black !important;*/
/*}*/
